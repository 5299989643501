//@ts-nocheck
import './Monitor.scss'
import { Monitor_Btn_List, Monitor_view } from './utils'
import CustomToolTip from '../../../../CommonComponents/CustomToolTip'
import { SearchSvg } from './svgs/Svgs'
import useMonitor from '../../../hooks/useMonitor'
import Payload from '../../grafana_panels/Payload'
import Communication from '../../grafana_panels/Communication'
import ADCS from '../../grafana_panels/ADCS'
import OBC from '../../grafana_panels/OBC'
import EPS from '../../grafana_panels/EPS'
import CustomLoader from '../../../custom_components/CustomLoader'
import useTrueTwin from '../../../hooks/useTrueTwin'

const Monitor = () => {

    const { panels, loading, selected_panel, change_selected_panel, monitor_view_type, handleFileUpload, change_monitor_view_type, fileName } = useMonitor()
    const { remoteAppConnected, TrueTwin } = useTrueTwin()

    return (
        <div className='monitor__container__wrapper'>
            {loading &&
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <CustomLoader loading={loading} />
                </div>
            }
            {!loading &&
                <div className='monitor__container'>
                    <div className='monitor__container__header'>
                        <div className='monitor__header__left__container'>
                            {Monitor_Btn_List.map((btn: { label: string, value: string, disabled?: boolean }) => {
                                const disabled_btn = btn.disabled || (btn.value === 'Payload' && !remoteAppConnected)
                                const hide_btn = (btn.value === 'Payload' && !remoteAppConnected) ? 'hide_btn' : ''
                                return (
                                    <button
                                        disabled={disabled_btn}
                                        className={`button ${selected_panel === btn.value && 'active'} ${hide_btn}`}
                                        onClick={() => change_selected_panel(btn.value)}
                                    >
                                        {btn.label}
                                    </button>
                                )
                            })}
                        </div>
                        <div className='monitor__header__right__container'>
                            {TrueTwin?.['Category'] === "FlatSat" && <button
                                className="button cursor-pointer"
                                onClick={handleFileUpload}
                            >
                                <CustomToolTip title={"Upload File"} placement='top'>
                                    <i className="fe fe-upload fs-25 color-text"></i>
                                </CustomToolTip>
                            </button>}
                            {fileName && <p className='m-0'>{fileName}</p>}
                            {Monitor_view.map((view: { title: string, icon: JSX.Element }) => (
                                <button
                                    className={`button ${monitor_view_type === view.title && 'active'}`}
                                    onClick={() => change_monitor_view_type(view.title)}
                                >
                                    <CustomToolTip title={view.title} placement='top'>
                                        {view.icon}
                                    </CustomToolTip>
                                </button>
                            ))}
                            <div className='search__container disabled'>
                                <SearchSvg />
                                <div className='input__container'>
                                    <input type="text" placeholder='Search' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='monitor__container__body'>
                        {selected_panel === 'EPS' && <EPS monitor_view_type={monitor_view_type} panels={panels} />}
                        {selected_panel === 'OBC' && <OBC monitor_view_type={monitor_view_type} panels={panels} />}
                        {selected_panel === 'ADCS' && <ADCS monitor_view_type={monitor_view_type} panels={panels} />}
                        {selected_panel === 'Communication' && <Communication monitor_view_type={monitor_view_type} panels={panels} />}
                        {selected_panel === 'Payload' && <Payload monitor_view_type={monitor_view_type} panels={panels} />}
                    </div>
                </div>
            }
        </div>
    )
}

export default Monitor