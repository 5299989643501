import './Footer.css'
import useUrlParams from '../../hooks/useUrlParams';
import useUrlNavigation from '../../hooks/useUrlNavigation';
import CircularProgressbar from '../../custom_components/circular_progress/CircularProgressbar';
import { useEffect, useState } from 'react';
import useScenario from '../../hooks/useScenario';
import useTrueTwin from '../../hooks/useTrueTwin';
import { CircularProgress } from '@mui/material';
import useViewer from '../../hooks/useViewer';
import Dialog from '../../../CommonComponents/Dialog';
import { get_formated_time } from '../../utils';
import { get_button_list } from './utils';
import useTimeline from '../../hooks/useTimeline';

const Footer = () => {

  const { scenario } = useScenario()
  const { loading } = useTimeline()
  const { navigate_to } = useUrlNavigation()
  const { dashboard, tab_name } = useUrlParams();
  const { current_simulation_time } = useViewer()
  const { TrueTwin, unDeployLoader, dis_connect_ground_station, navigate_to_truetwin_details } = useTrueTwin()
  const [openDialouge, setOpenDialouge] = useState<boolean>(false);
  const [scenarioTime, setScenarioTime] = useState<{
    "start_time": number;
    "end_time": number;
  }>({
    "start_time": 0,
    "end_time": 0,
  })

  const show_Left_right_btn_container = dashboard !== 'operate'
  const show_scenario__timer__container = dashboard !== 'quick-deploy' && current_simulation_time > 0

  const ButtonsList = get_button_list(tab_name, dashboard)


  useEffect(() => {
    if (scenario) {
      const start_time = scenario?.['simulationStartTime']
      const end_time = scenario?.['simulationStartTime'] + (scenario?.['simulationDuration'] * 60)
      setScenarioTime({
        "start_time": start_time,
        "end_time": end_time
      })
    }
  }, [scenario]);


  const onclick = () => {
    if (dashboard === "summary") {
      navigate_to_truetwin_details()
    } else {
      setOpenDialouge(true)
    }
  }

  return (
    <div className='command__center_footer'>
      {show_Left_right_btn_container &&
        <div className='footer__left_button_container'>
          <div className='footer__left__button'>
            <span>
              {dashboard === 'quick-deploy' ? TrueTwin?.['Name'] : scenario?.['scenarioName']}
              <div className='button_tooltip'>
                {dashboard === 'quick-deploy' ? TrueTwin?.['Name'] : scenario?.['scenarioName']}
              </div>
            </span>
            <span>{dashboard === 'summary' ? 'Summary' : dashboard === 'quick-deploy' ? 'Quick Deploy' : dashboard === 'simulation' ? 'Simulation' : 'Operate'}</span>
          </div>
        </div>
      }
      <div className='footer__center_button_container'>
        {ButtonsList.map((button, index) => {
          const { label, svg, show, active } = button;
          return (
            <button
              key={index}
              // disabled={loading}
              className={`footer__center__button ${active && 'active'} ${!show && 'hide'}`}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                const tabname = label.toLowerCase() === 'plan' ? 'plan-onboard' : label.toLowerCase()
                navigate_to(tabname)
              }}
            >
              {svg}
              <span>{label}</span>
            </button>
          )
        })}
      </div>
      {(show_Left_right_btn_container && show_scenario__timer__container) &&
        <div className='scenario__timer__container'>
          <CircularProgressbar
            simulation_current_time={current_simulation_time / 1000}
            simulation_start_time={scenarioTime?.start_time}
            simulation_end_time={scenarioTime?.end_time}
          />
          <div className='separator' />
          <div className='time__container'>
            <span>{current_simulation_time && get_formated_time(current_simulation_time / 1000)}</span>
            <span>{scenarioTime?.end_time && get_formated_time(scenarioTime?.end_time)}</span>
          </div>
        </div>
      }
      {show_Left_right_btn_container &&
        <div className='footer__right_button_container'>
          <button
            disabled={unDeployLoader ? true : false}
            className='footer__right_button' onClick={onclick}>
            {dashboard === "summary" ? "Close" : unDeployLoader ? 'Retiring' : dashboard !== 'quick-deploy' ? "End Simulation" : 'Retire'}
            {unDeployLoader && <CircularProgress size={16} color='inherit' />}
          </button>
        </div>
      }
      <Dialog onClick={(event: boolean) => {
        if (event) {
          dis_connect_ground_station()
        }
        setOpenDialouge(false)
      }} openModel={openDialouge} comment={`Are you sure you want to ${dashboard !== 'quick-deploy' ? "end this simulation" : 'retire this truetwin'} ?`} />
    </div>
  )
}

export default Footer