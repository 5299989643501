import CustomToolTip from "../../../../../CommonComponents/CustomToolTip";
import useOnBoardSchedule, { ScheduleData } from "../../../../hooks/useOnBoardSchedule";
import { event_status_state } from "./Svgs";
import './ContactTimeline.scss'
import { ButtonToolbar, OverlayTrigger } from "react-bootstrap";
import { GroundTaskSvg, PayloadTaskSvg } from "../../modals/Svgs";
import { calculate_duration, get_formated_time } from "../../../../utils";
import useTimeline from "../../../../hooks/useTimeline";
import useViewer from "../../../../hooks/useViewer";



const OnboardContact = () => {
    const { current_simulation_time } = useViewer()
    const { timelineScheduleData } = useOnBoardSchedule()
    const { window_width, timeframe_details } = useTimeline()
    const { frame_start_time, frame_duration } = timeframe_details

    const get_current_contact = (start_time: number, end_time: number) => {
        const current_time = current_simulation_time / 1000
        if (current_time >= start_time && current_time <= end_time) {
            return 'ongoing__event'
        } else if (current_time < start_time) {
            return 'upcomming__event'
        } else if (current_time > end_time) {
            return 'past__event'
        }
    }
    const get_contact_status = (start_time: number, end_time: number) => {
        const current_time = current_simulation_time / 1000
        if (current_time >= start_time && current_time <= end_time) {
            return 'ongoing'
        } else if (current_time < start_time) {
            return 'upcoming'
        } else if (current_time > end_time) {
            return 'completed'
        } else {
            return 'not_confirm'
        }
    }

    return (
        <div className="task__details__container" style={{ width: `${window_width}px` }}>
            {timelineScheduleData?.length > 0 && timelineScheduleData.map((task: ScheduleData, index) => {
                const task_start_time = task.startTime;
                const task_end_time = task.endTime;
                const task_duration = task_end_time - task_start_time;
                const per_sec_pixel = window_width / frame_duration;
                const card__width = task_duration * per_sec_pixel;
                const task_type_icon = task.type === 'Payload Task' ? <PayloadTaskSvg /> : <GroundTaskSvg />
                const left_position = (task_start_time - frame_start_time) * per_sec_pixel;
                // const task_status = task?.['status'] === 'completed' ? 'confirm' : task?.['status'] === 'waiting' ? 'upcoming' : task?.['status'] === 'active' ? 'ongoing' : task?.['status'] === 'Not Completed' ? 'not_confirm' : 'failed';
                const task_status = get_contact_status(task_start_time, task_end_time)
                const task_name = `${task?.['taskName']}`
                // const contact_type = task.status === 'active' ? 'ongoing__event' : task?.['status'] === 'waiting' ? 'upcomming__event' : 'past__event'
                const contact_type = get_current_contact(task_start_time, task_end_time)

                return (
                    <ButtonToolbar key={index}>
                        <OverlayTrigger trigger={['hover', 'focus']} placement="top"
                            overlay={(
                                <div className={`contacts_overview`}>
                                    <div className='d-flex gap-2 align-items-center w-100 justify-content-between'>
                                        <div className="d-flex gap-2 align-items-center w-70">
                                            <div className="contact_info w-100">
                                                <div className="contact_title">
                                                    <span>{task?.['taskName']}</span>
                                                </div>
                                                <span>Task Name</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 align-items-center justify-content-between flex-row-reverse'>
                                        {task?.['properties']?.['ExtraDetails']?.['coordinates'] &&
                                            Object.keys(task?.['properties']?.['ExtraDetails']?.['coordinates']).map((coordinate: any, index: number) => {
                                                return (
                                                    <div className='contact_info' key={index}>
                                                        <span>{task?.['properties']?.['ExtraDetails']?.['coordinates']?.[coordinate]}</span>
                                                        <span>{coordinate}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className='contact_info'>
                                            <CustomToolTip placement="bottom" title={task?.['type'] === 'Contact Task' ? task?.['properties']?.['GroundStationName'] : task?.['properties']?.['PayloadName']}>
                                                <span>{task?.['type'] === 'Contact Task' ? task?.['properties']?.['GroundStationName'] : task?.['properties']?.['PayloadName']}</span>
                                            </CustomToolTip>
                                            <span>{task?.['type'] === 'Contact Task' ? 'Ground Station' : 'Payload'}</span>
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 align-items-center justify-content-between'>
                                        <div className='contact_info'>
                                            <span>{task_start_time && `${get_formated_time(task_start_time)}`}</span>
                                            <span>Start Time</span>
                                        </div>
                                        <div className='contact_info'>
                                            <span>{(task_start_time && task_end_time) && `${calculate_duration(task_start_time, task_end_time)}`}</span>
                                            <span>Contact Duration</span>
                                        </div>
                                    </div>
                                </div>
                            )}>
                            <div className={`task__details__card ${contact_type}`}
                                style={{
                                    transform: `translateX(${left_position}px)`,
                                    width: `${card__width}px`,
                                }}
                            >
                                <div className="event__indicator">
                                    <CustomToolTip title={event_status_state[task_status].state} placement="top">
                                        {event_status_state[task_status].svg}
                                    </CustomToolTip>
                                </div>
                                <div className="w-100 d-flex h-100 align-items-center justify-content-center">
                                    {card__width >= 25 &&
                                        <div className="task__card__container" style={{ width: `${card__width}px` }}>
                                            <div className="task_card_svg_container">
                                                {task_type_icon}
                                            </div>
                                            <div className={`task__title ${card__width > 50 && 'show'}`}>{task_name}</div>
                                        </div>
                                    }
                                    {card__width < 25 && <div style={{ width: '20px', height: '30px' }} />}
                                </div>
                            </div>
                        </OverlayTrigger>
                    </ButtonToolbar>

                )
            })}
        </div>
    )
}
export default OnboardContact